import { render, staticRenderFns } from "./HelpCenter.vue?vue&type=template&id=c3e75790&scoped=true&"
import script from "./HelpCenter.vue?vue&type=script&setup=true&lang=js&"
export * from "./HelpCenter.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./HelpCenter.vue?vue&type=style&index=0&id=c3e75790&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3e75790",
  null
  
)

export default component.exports