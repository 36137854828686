<template>
  <b-modal
    v-model="showModal"
    id="terms-modal"
    size="lg"
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-escape
    centered
  >
    <SmeAlert v-if="error" level="danger">{{ error }}</SmeAlert>
    <AppLoading v-if="loading" />
    <Terms
      :termsAccepted="termsAccepted"
      @update:termsAccepted="termsAccepted = $event"
      :diligenceAccepted="diligenceAccepted"
      @update:diligenceAccepted="diligenceAccepted = $event"
      :terms-accepted-text="`I have read and accept the <a href='${termsUrl}' target='_blank'>Terms of Business</a>`"
      diligence-accepted-text="I confirm that the key employee due diligence requirements (i.e. right to work checks, identity verification, DBS, references) have been met."
    />
    <b-button class="terms__continue" variant="primary" :disabled="!canSubmit" @click="handleTermsSubmit"
      >Continue</b-button
    >
  </b-modal>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue';
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading.vue';
import Terms from '@/components/Terms.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import useLaunchState from '@/state/composables/useLaunchState';
import useAnalytics from '@/state/composables/useAnalytics';

const { postAnalyticsEvent } = useAnalytics();
const { canSignTerms } = useFeatureFlags();
const { outstandingTodos, getLaunchState } = useLaunchState();

const termsAccepted = ref(false);
const diligenceAccepted = ref(false);
const termsUrl = ref(null);
const agreementId = ref(null);
const loading = ref(false);
const error = ref(false);

const showModal = computed(
  () =>
    canSignTerms.value &&
    (outstandingTodos.value ? outstandingTodos?.value?.find(t => t.action === 'accept.terms') !== undefined : true),
);

const canSubmit = computed(() => termsAccepted.value && diligenceAccepted.value);

const handleTermsSubmit = async () => {
  try {
    await ApiClient.acceptTerms(agreementId.value);
    postAnalyticsEvent({
      intent: 'complete_accept_terms',
      task: 'accept_terms',
      action: 'click',
    });
  } catch (e) {
    error.value = e?.message || 'Unable to accept SME terms, please try again later';
    return;
  }
  await getLaunchState();
};

onBeforeMount(async () => {
  loading.value = true;
  try {
    const { contents_url, agreement_id } = await ApiClient.smeGetTerms();
    await getLaunchState();
    termsUrl.value = contents_url;
    agreementId.value = agreement_id;
  } catch (e) {
    error.value = e?.message || 'Unable to fetch terms. Please try again later';
  }
  loading.value = false;
});
</script>

<style lang="scss" scoped>
.terms__continue {
  width: 100%;
  margin-top: 1rem;
}
</style>
