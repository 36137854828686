<!-- eslint-disable vue/no-deprecated-dollar-listeners-api -->

<template>
  <div class="page-content-wrapper">
    <app-aside :visible="sidebarVisible" @toggle="handleToggle" />
    <div class="page-content-wrapper-inner">
      <!-- Header -->
      <header :class="['page-header', { 'page-header--visible': headerVisible }]" role="banner">
        <button class="burger" @click="handleToggle">
          <font-awesome-icon :icon="['fad', 'bars']" />
        </button>

        <div class="company-name d-flex align-items-center">
          <ol class="breadcrumb page-breadcrumb">
            <li class="breadcrumb-item">
              <router-link v-if="state.company" :to="{ name: 'apps' }">{{ state.company.name }}</router-link>
              <app-loading v-else></app-loading>
            </li>
            <li class="breadcrumb-item" v-for="breadcrumb in previous" :key="breadcrumb.label">
              <router-link :to="breadcrumb.to">{{ breadcrumb.label }}</router-link>
            </li>
            <li v-if="title" class="breadcrumb-item active">{{ title }}</li>
          </ol>
        </div>

        <div class="ml-auto d-flex quick-search">
          <template v-if="state.claims?.a || state.claims?.m">
            <quick-search />
          </template>
        </div>

        <div class="d-flex ml-5">
          <div class="show">
            <a
              v-b-toggle.user-menu
              class="header-icon header-icon-big ml-2"
              title="User"
              aria-expanded="false"
              data-toggle="dropdown"
              data-testid="userProfile"
            >
              <font-awesome-icon class="icon" :icon="['fad', 'user-circle']" />
            </a>
            <b-collapse id="user-menu" class="dropdown-menu dropdown-lg show user-menu" x-placement="bottom-start">
              <router-link :to="{ name: 'profile' }">
                <div class="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top">
                  <div class="d-flex flex-row align-items-center mt-1 mb-1 color-white">
                    <span class="mr-2">
                      <font-awesome-icon class="icon" :icon="['fad', 'user-circle']" size="4x" />
                    </span>
                    <div class="info-card-text">
                      <div class="fs-lg text-truncate text-truncate-lg">
                        {{ state.user.username }}
                      </div>
                      <span class="text-truncate text-truncate-md opacity-80">{{ state.user.email }}</span>
                    </div>
                  </div>
                </div>
              </router-link>
              <template v-if="accountNames.length > 0">
                <a
                  v-for="name in accountNames"
                  :key="name"
                  @click="handleSwitchAccounts($event)"
                  class="dropdown-item fw-500 pt-3 pb-3"
                  >{{ name }}</a
                >
                <hr class="m-0" />
              </template>
              <router-link
                v-if="showRaiseSwitcher"
                :to="{ name: 'talent-pool-root' }"
                class="dropdown-item fw-500 pt-3 pb-3 logo"
              >
                <span data-i18n="drpdwn.raise-switch">
                  <div class="logo-wrapper">
                    <img class="raise-logo" :src="themeImage('raise-logo-symbol.svg')" alt="Raise Logo" />
                  </div>
                  Switch to Raise</span
                >
              </router-link>
              <a class="dropdown-item fw-500 pt-3 pb-3 icon" @click="logout">
                <span data-i18n="drpdwn.page-logout"><Logout />Log Out</span>
              </a>
            </b-collapse>
          </div>
        </div>
      </header>

      <!-- Main -->
      <main id="js-page-content" role="main" class="page-content streams">
        <page-sub-header v-if="title || subtitle" :title="title" :subtitle="subtitle" :icon="localIcon" class="mb-0">
          <template #extra-title><slot name="extraTitle"></slot></template>
          <template #header><slot name="header"></slot></template>
        </page-sub-header>

        <slot name="after-header"></slot>

        <div class="info-box my-4" v-if="infoBoxSlotHasContent">
          <slot name="infobox"></slot>
        </div>

        <b-row class="page-content-inner">
          <b-col v-if="!infoBoxSlotHasContent || (infoBoxSlotHasContent && mergedErrors)" sm="12" class="mb-g">
            <sme-alert v-if="mergedErrors" class="my-3" level="danger">
              <p>
                <strong>{{ mergedErrors }}</strong>
              </p>
              <p>If this error persists please contact support</p>
            </sme-alert>
          </b-col>
          <slot name="before-content"></slot>
          <b-col v-if="!$scopedSlots['custom-content'] && !mergedErrors">
            <sme-card large>
              <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                <slot :name="slot" v-bind="scope"></slot>
              </template>
            </sme-card>
          </b-col>
          <slot v-if="!mergedErrors || ignoreError" name="custom-content"></slot>
        </b-row>
        <GetStartedModal :onboarding="true" />
        <ReferralsModal v-if="shouldShowReferralsAdvertisment && !isDisablePortalReferrals" />
      </main>
    </div>
  </div>
</template>

<script>
import ApiClient, { logout as logoutFromApp } from '@/ApiClient';
import AppAside from '@/components/AppAside.vue';
import AppLoading from '@/components/AppLoading.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import QuickSearch from '@/components/QuickSearch.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import { themeImage } from '@/Theme';
import Logout from '@/assets/icons/Logout.vue';
import State from '@/state/State';
import GetStartedModal from '@/pages/talent-pool/components/GetStartedModal.vue';
import { isFlexWorkLike } from '@/guards';
import ReferralsModal from '@/components/ReferralsModal.vue';

export default {
  name: 'AppPage',
  components: {
    AppAside,
    AppLoading,
    PageSubHeader,
    QuickSearch,
    SmeAlert,
    SmeCard,
    Logout,
    GetStartedModal,
    ReferralsModal,
  },
  props: {
    title: String,
    subtitle: String,
    error: [String, Error],
    icon: [String, Array],
    previous: Array,
    ignoreError: Boolean,
    infoBoxItems: Array,
  },
  data() {
    const screenWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
    const { isEnableTalentPool } = useFeatureFlags();
    const isMobile = screenWidth <= 768;

    return {
      headerPreviousScroll: 0,
      headerVisible: true,
      sidebarVisible: isMobile ? false : JSON.parse(localStorage.getItem('sme.sidebar')),
      localError: undefined,
      state: State.state,
      switchAccounts: [],
      showRaiseSwitcher: isEnableTalentPool.value && isFlexWorkLike(),
    };
  },
  setup() {
    const { isDisableRegistration, shouldShowReferralsAdvertisment, isDisablePortalReferrals } = useFeatureFlags();
    return { isDisableRegistration, shouldShowReferralsAdvertisment, isDisablePortalReferrals, themeImage };
  },
  async mounted() {
    window.addEventListener('scroll', this.handleScroll);
    if (this.isUnregisteredSme()) {
      const query = this.$route.query;
      this.$router.replace({ name: this.isDisableRegistration ? 'login' : 'register', query });
    }
    const data = (await ApiClient.getAuthSwitch()) || [];
    this.switchAccounts = data?.data || [];
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    localIcon() {
      if (this.icon && !Array.isArray(this.icon)) {
        return ['fad', this.icon];
      }
      return this.icon;
    },
    notificationsTitle() {
      if (this.state.user.todos.length) {
        return `You got ${this.state.user.todos.length} todos`;
      } else {
        return 'Hooray! you have no pending todos';
      }
    },
    mergedErrors() {
      return this.error || this.localError;
    },
    infoBoxSlotHasContent() {
      return !!this.$slots.infobox;
    },
    accountNames() {
      return this.switchAccounts.map(a => a.name);
    },
  },
  methods: {
    isUnregisteredSme() {
      const company = State.state?.company;
      return State.state?.isSelfServeSME && !company.default_pay_schedule_id;
    },
    isSelfFunded() {
      return State.state?.isSelfServeSME;
    },
    handleToggle() {
      this.sidebarVisible = !this.sidebarVisible;
      localStorage.setItem('sme.sidebar', JSON.stringify(this.sidebarVisible));
    },
    handleScroll() {
      const currentScroll = window.innerHeight + window.scrollY;
      const isFullyScrolled = currentScroll >= document.body.scrollHeight;
      this.headerVisible = !isFullyScrolled && this.headerPreviousScroll > currentScroll;
      this.headerPreviousScroll = currentScroll;
    },
    logout() {
      logoutFromApp();
    },
    async handleSwitchAccounts(newAccount) {
      const targetCompanyId = this.switchAccounts.find(a => a.name === newAccount.target.textContent).company_id;
      try {
        await ApiClient.postAuthSwitch(targetCompanyId);
      } catch (error) {
        this.$appToast(error.message, {
          title: `Unable to switch accounts`,
          variant: 'danger',
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.page-content-wrapper {
  min-height: calc(100vh + env(safe-area-inset-bottom) * 1.25);
  background-color: var(--palette-color-base-background);
  flex-direction: row;
}

.page-content-wrapper-inner {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100vw;
}

.page-header {
  box-shadow: none;
  height: 5.25rem;
  position: sticky;
  transition: top 0.25s;
  width: 100%;
  top: 0px;
}

@media (max-width: 768px) {
  .page-header:not(.page-header--visible) {
    top: -100px;
  }
}

.page-content {
  overflow-x: hidden;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.header-icon {
  cursor: pointer !important;
}

.fa {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: 'Font Awesome 5 Pro';
}

.header-icon-big svg {
  font-size: 2rem !important;
}

.company-name h3 {
  margin-bottom: 0;
}

@media (max-width: 900px) {
  .company-name {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .quick-search {
    visibility: hidden;
  }

  .user-menu {
    margin-top: 16px !important;
  }
}

.burger {
  color: var(--palette-color-brand-primary);
  font-size: 20px;
  text-align: left;
  padding: 0 0.5rem;
  margin-right: 1.5rem;
  appearance: none;
  background: none;
  border: none;
}

.page-header .header-icon:not(.btn):hover > [class*='fa-']:only-child,
.page-header .header-icon:not(.btn)[data-toggle='dropdown'][aria-expanded='true'] > [class*='fa-']:first-child {
  color: var(--palette-color-brand-primary) !important;
}

.header-icon:not(.btn)[data-toggle='dropdown'][aria-expanded='true']::after {
  background: var(--palette-color-brand-primary) !important;
  opacity: 0.4 !important;
  top: 0.85rem !important;
}
</style>
