// this needs to be updated to ensure that we're uisng the correct description and price
// rather than the ones we hold on stripe
export const STRIPE_TO_PORTAL_PROD = {
  NP4yGpwnEx8yd3: 'ENTRY', // prod
  NP4zh4n6vmTVKt: 'STANDARD', // prod
  NP51ySUgdAQEVf: 'PREMIUM', // prod
};

export const STRIPE_TO_PORTAL_DEV = {
  L0K3q5K1DW7b8J: 'STANDARD', // dev
  KK0OCvuWgKNjwv: 'ENTRY', // dev
  KK0Nvn46VNp6QC: 'PREMIUM', // dev
};

export const getStripeToPortal = planId => {
  if (process.env.NODE_ENV === 'production') {
    return STRIPE_TO_PORTAL_PROD[planId];
  } else {
    return STRIPE_TO_PORTAL_DEV[planId];
  }
};

export const PORTAL_TO_STRIPE_PROD = {
  ENTRY: 'NP4yGpwnEx8yd3', // prod
  STANDARD: 'NP4zh4n6vmTVKt', // prod
  PREMIUM: 'NP51ySUgdAQEVf', // prod
};

export const PORTAL_TO_STRIPE_DEV = {
  ENTRY: 'KK0OCvuWgKNjwv', // dev
  STANDARD: 'L0K3q5K1DW7b8J', // dev
  PREMIUM: 'KK0Nvn46VNp6QC', // dev
};

export const getPortalToStripe = plan => {
  if (process.env.NODE_ENV === 'production') {
    return PORTAL_TO_STRIPE_PROD[plan];
  } else {
    return PORTAL_TO_STRIPE_DEV[plan];
  }
};

export const PLAN_METADATA = {
  ENTRY: {
    name: 'Entry',
    description: 'For teams who want to offer streamlined cost of living support.',
    price: 'From £72 + VAT / month',
    order: 1,
    prices: {
      '1 - 25': '£72',
      '25 - 50': '£95',
      '50 - 100': '£109',
      '100 - 150': '£128',
      '150 - 200': '£165',
      '200 - 250': '£207',
      '250 - 350': '£283',
      '350 - 450': '£344',
      '450 - 550': '£396',
      '550 - 750': '£523',
    },
    features: {
      '👀 Visibility of income': true,
      '💰 Workplace savings (4.7% variable)': true,
      '🚀 Flexible pay': true,
      '🏠 Funding for Flexible pay': false,
      '📚 Financial education': true,
      '✅ Benefits checker': true,
      '💸 Discounts': true,
      '🎁 Gift cards': false,
      '3️⃣ 3 free streams': false,
      '👩‍🏫 Live financial coaches': false,
    },
  },
  STANDARD: {
    name: 'Standard',
    description: 'For teams who who want to offer comprehensive cost of living support.',
    price: 'From £95 + VAT / month',
    order: 2,
    prices: {
      '1 - 25': '£95',
      '25 - 50': '£140',
      '50 - 100': '£194',
      '100 - 150': '£256',
      '150 - 200': '£331',
      '200 - 250': '£414',
      '250 - 350': '£567',
      '350 - 450': '£688',
      '450 - 550': '£792',
      '550 - 750': '£1046',
    },
    features: {
      '👀 Visibility of income': true,
      '💰 Workplace savings (4.7% variable)': true,
      '🚀 Flexible pay': true,
      '🏠 Funding for Flexible pay': true,
      '📚 Financial education': true,
      '✅ Benefits checker': true,
      '💸 Discounts': true,
      '🎁 Gift cards': true,
      '3️⃣ 3 free streams': false,
      '👩‍🏫 Live financial coaches': false,
    },
  },
  PREMIUM: {
    name: 'Premium',
    description: 'For teams who want to go above and beyond cost of living support.',
    price: 'From £125 + VAT / month',
    order: 3,
    prices: {
      '1 - 25': '£125',
      '25 - 50': '£200',
      '50 - 100': '£308',
      '100 - 150': '£427',
      '150 - 200': '£552',
      '200 - 250': '£690',
      '250 - 350': '£945',
      '350 - 450': '£1147',
      '450 - 550': '£1320',
      '550 - 750': '£1743',
    },
    features: {
      '👀 Visibility of income': true,
      '💰 Workplace savings (4.7% variable)': true,
      '🚀 Flexible pay': true,
      '🏠 Funding for Flexible pay': true,
      '📚 Financial education': true,
      '✅ Benefits checker': true,
      '💸 Discounts': true,
      '🎁 Gift cards': true,
      '3️⃣ 3 free streams': true,
      '👩‍🏫 Live financial coaches': true,
    },
  },
};
