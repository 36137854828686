<template>
  <app-page
    :title="(employee && employee.full_name) || 'Employee'"
    subtitle="Employee details"
    :error="errorEmployee"
    :previous="[{ label: 'Employees', to: { name: 'employees' } }]"
    icon="user"
    :key="$route.params.employeeId"
    class="employee-page"
  >
    <template v-if="showEmployeeFlag" v-slot:after-header>
      <sme-alert :level="flagLevel" class="mt-3">{{ flagContent }}</sme-alert>
    </template>
    <template v-slot:infobox>
      <sme-info-box title="Managing employees" id="managing-employees" save-state>
        <p>
          You may need to reconfigure an employee or change settings if e.g. they switch banks or receive a pay rise.
          You can do this on the <strong>Employee</strong> tab.
        </p>

        <p>
          From here, employee data can be edited by clicking the <strong>Edit</strong> button. More significant changes,
          such as pausing service, opting out or resetting an employee's password can be done using the buttons at the
          top of the page.
        </p>
      </sme-info-box>

      <sme-info-box title="Viewing shifts" id="viewing-shifts" save-state>
        <p>
          You may want to view the shifts that an employee has worked. You can do this on the <strong>Shifts</strong>
          tab.
        </p>
        <p>You can filter shifts by dates, or by pay periods by using the controls above the table.</p>
      </sme-info-box>

      <sme-info-box title="Viewing pay" id="viewing-pay" save-state>
        <p>
          You may want to view the earnings that have been paid to the employee, and how their streaming activity
          affects their pay. You can do this on the <strong>Pay</strong> tab.
        </p>
        <p>
          This tab shows you the employee's total earnings for a pay period (paid by you), the total amount that they
          streamed during that period, and the final total that was paid to them after their streams were settled. You
          can also download the statement that is sent to the employee on pay day, which summarises this information.
        </p>
      </sme-info-box>
    </template>

    <template v-if="!loadingEmployee && employee && employee.employee_id" v-slot:extraTitle>
      <sme-badge :variant="badgeVariant(employee)" class="ml-2" large>{{ convertStatus(employee) }}</sme-badge>
      <sme-badge v-if="employee.has_savings_account" :variant="primary" class="ml-2" large>SAVINGS ACCOUNT</sme-badge>
    </template>

    <template v-if="!loadingEmployee && employee && employee.employee_id" v-slot:header>
      <employee-actions :employee="employee" />
    </template>

    <template v-if="!loadingEmployee" v-slot:before-content>
      <b-col sm="12" class="mb-4">
        <page-metrics :metrics="employeeMetrics" />
      </b-col>
    </template>

    <template v-if="loadingEmployee">
      <app-loading />
    </template>
    <template v-else>
      <navigable-tabs>
        <b-tab :id="TAB_NAMES.employee">
          <template #title>
            <h4>Employee</h4>
          </template>
          <employee-form
            class="pt-4 pb-2 px-2"
            v-model="employee"
            :balance="balance"
            :field-permissions="fieldPermissions"
            :saving="saving"
            :state="state"
            :integrations-state="integrationsState"
            :hide-earnings="hideEarnings"
            :hide-salary="hideSalary"
            :paySchedules="paySchedules"
            :employeePayScheduleName="employeePayScheduleName"
            @save="onSave"
          ></employee-form>
        </b-tab>
        <b-tab :id="TAB_NAMES.shifts">
          <template #title>
            <h4>Shifts</h4>
          </template>
          <shifts-list
            v-if="!displayNetPayShifts"
            :field-keys="hideShiftsHoursAndRate ? shiftFieldKeysHiddenHoursAndRate : shiftFieldKeys"
            :filter-keys="shiftFilterKeys"
            :employeeId="employee.employee_id"
            :fixed-filters="{ [SHIFT_FILTER_KEYS.PAY_SCHEDULE]: employee.pay_schedule_id }"
            :fixed-search-text="employee.full_name"
            class="pt-4 px-2"
            nested
          />
          <net-pay-shifts-list
            v-if="displayNetPayShifts"
            :field-keys="netPayShiftFieldKeys"
            :filter-keys="shiftFilterKeys"
            :employeeId="employee.employee_id"
            :fixed-filters="{ [SHIFT_FILTER_KEYS.PAY_SCHEDULE]: employee.pay_schedule_id }"
            :fixed-search-text="employee.full_name"
            class="pt-4 px-2"
            nested
          />
        </b-tab>
        <b-tab v-if="isEnableStreamedWagesPage" :id="TAB_NAMES.streams">
          <template #title>
            <h4>Streams</h4>
          </template>
          <StreamedWagesList
            :employeeId="employee.employee_id"
            :fieldKeys="streamFieldKeys"
            :filterKeys="streamFilterKeys"
            class="pt-4 px-2"
            nested
          />
        </b-tab>
        <b-tab :id="TAB_NAMES.absences">
          <template #title>
            <h4>Absences</h4>
          </template>
          <AbsencesList
            :fixedSearchText="employee.full_name"
            :employeeId="employee.employee_id"
            :fieldKeys="absenceFieldKeys"
            class="pt-4 px-2"
            nested
          />
        </b-tab>
        <b-tab v-if="!hideEarnings && !hideSalary" :id="TAB_NAMES.pay" @shown="getPay">
          <template #title>
            <h4>Pay</h4>
          </template>
          <section class="pt-4 px-2">
            <app-loading :loading="loadingPay" />
            <template v-if="!loadingPay">
              <sme-alert v-if="errorPay" :level="ALERT_LEVELS.DANGER">{{ errorPay }}</sme-alert>
              <employee-pay
                v-else
                :salaries="salaries"
                :employeeName="employee.full_name"
                :offCyclePayments="employeeOffCyclePayments"
              />
            </template>
          </section>
        </b-tab>
        <b-tab :id="TAB_NAMES.history" @shown="getHistory">
          <template #title>
            <h4>History</h4>
          </template>
          <section class="pt-4 px-2">
            <app-loading :loading="loadingHistory" />
            <template v-if="!loadingHistory">
              <sme-alert v-if="errorHistory" :level="ALERT_LEVELS.DANGER">{{ errorHistory }}</sme-alert>
              <employee-history
                v-else
                :employee="employee"
                :history="history"
                :salary-history="!hideSalary ? salaryHistory : []"
              />
            </template>
          </section>
        </b-tab>
        <b-tab :id="TAB_NAMES.lump_sum" v-if="showLumpSumTab" @shown="getLumpSumData">
          <template #title>
            <h4>Lump Sum</h4>
          </template>
          <section class="pt-4 px-2">
            <app-loading :loading="loadingLumpSum" />
            <template v-if="!loadingLumpSum">
              <sme-alert v-if="errorPaySchedule" :level="ALERT_LEVELS.DANGER">{{ errorPaySchedule }}</sme-alert>
              <employee-lump-sum-pay v-else :employee="employee" :future-pay-periods="futurePayPeriods" />
            </template>
          </section>
        </b-tab>
        <b-tab v-if="showLastDayPay" :id="TAB_NAMES.last_day_pay">
          <template #title>
            <h4>Last Day Pay</h4>
          </template>
          <EmployeeLastDayPay
            class="pt-4 pb-2 px-2"
            :employee="employee"
            :balance="balance"
            :currency="state.company.default_currency"
            @finalPayProcessed="retrieveAndUpdatePageData"
          />
        </b-tab>
        <b-tab v-if="employee.other_entities && employee.other_entities.length > 0" :id="TAB_NAMES.assignments">
          <template #title>
            <h4>Other Records</h4>
          </template>
          <EmployeeAssignments
            :employee="employee"
            :payschedules="paySchedules"
            :otherAssignments="employee.other_entities"
            class="pt-4 px-2"
          />
        </b-tab>
      </navigable-tabs>
    </template>
  </app-page>
</template>

<script>
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import NavigableTabs from '@/components/NavigableTabs.vue';
import PageMetrics from '@/components/PageMetrics.vue';
import SmeAlert, { ALERT_LEVELS } from '@/components/atoms/SmeAlert.vue';
import SmeBadge from '@/components/atoms/SmeBadge.vue';
import SmeInfoBox from '@/components/atoms/SmeInfoBox.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import { employeeBadges } from '@/mixins/EmployeeBadges.js';
import { employeeHandle } from '@/mixins/EmployeeHandle.js';
import EmployeeActions from '@/pages/employee/components/EmployeeActions.vue';
import EmployeeAssignments from '@/pages/employee/components/EmployeeAssignments.vue';
import EmployeeForm from '@/pages/employee/components/EmployeeForm.vue';
import EmployeeHistory from '@/pages/employee/components/EmployeeHistory.vue';
import EmployeePay from '@/pages/employee/components/EmployeePay.vue';
import EmployeeLastDayPay from '@/pages/employee/components/EmployeeLastDayPay.vue';
import EmployeeLumpSumPay from '@/pages/employee/components/EmployeeLumpSumPay.vue';
import AbsencesList from '@/pages/absences/components/AbsencesList.vue';
import ShiftsList from '@/pages/shifts/components/ShiftsList.vue';
import NetPayShiftsList from '@/pages/shifts/components/NetPayShiftsList.vue';
import { ABSENCE_FIELD_KEYS } from '@/pages/absences/constants';
import { SHIFT_FILTER_KEYS, SHIFT_FIELD_KEYS, NET_PAY_SHIFT_FIELD_KEYS } from '@/pages/shifts/constants';
import StreamedWagesList from '@/pages/streamed-wages/StreamedWagesList.vue';
import { STREAM_FIELD_KEYS, STREAM_FILTER_KEYS } from '@/pages/streamed-wages/constants';
import IntegrationsState from '@/state/IntegrationsState';
import State from '@/state/State';
import useEmployeesReport from '@/state/composables/useEmployeesReport';
import { toCurrency } from '@/utils';
import { EMPLOYEE_PAY_TYPE, updateEmployeeAndBanking } from '@/utils/Employee';
import moment from 'moment';

const TAB_KEYS = {
  employee: 'employee',
  streams: 'streams',
  shifts: 'shifts',
  pay: 'pay',
  history: 'history',
  absences: 'absences',
  assignments: 'assignments',
  last_day_pay: 'last_day_pay',
  lump_sum: 'lump_sum',
};

export default {
  name: 'Employee',
  mixins: [employeeBadges, employeeHandle],
  components: {
    AbsencesList,
    AppLoading,
    AppPage,
    EmployeeActions,
    EmployeeForm,
    EmployeeHistory,
    EmployeeLumpSumPay,
    EmployeePay,
    EmployeeAssignments,
    EmployeeLastDayPay,
    NavigableTabs,
    PageMetrics,
    ShiftsList,
    NetPayShiftsList,
    SmeAlert,
    SmeBadge,
    SmeInfoBox,
    StreamedWagesList,
  },
  data() {
    return {
      ALERT_LEVELS,
      SHIFT_FILTER_KEYS,
      TAB_NAMES: TAB_KEYS,
      employee: undefined,
      employeeId: undefined,
      balance: undefined,
      paySchedules: undefined,
      history: undefined,
      salaries: undefined,
      salaryHistory: undefined,
      futurePayPeriods: undefined,
      saving: false,
      noRecentShifts: false,
      notYetPaid: false,
      showEmployeeFlag: false,
      loadingEmployee: true,
      loadingHistory: true,
      loadingLumpSum: true,
      loadingPay: true,
      initialHistory: true,
      initialPay: true,
      errorEmployee: undefined,
      errorHistory: undefined,
      errorPay: undefined,
      errorPaySchedule: undefined,
      state: State.state,
      integrationsState: IntegrationsState.state,
      shiftFieldKeys: [
        SHIFT_FIELD_KEYS.WORKED_ON,
        SHIFT_FIELD_KEYS.HOURS,
        SHIFT_FIELD_KEYS.WAGES,
        SHIFT_FIELD_KEYS.WAGES_PER_HOUR,
        SHIFT_FIELD_KEYS.REMOVE_SHIFT,
      ],
      shiftFieldKeysHiddenHoursAndRate: [
        SHIFT_FIELD_KEYS.WORKED_ON,
        SHIFT_FIELD_KEYS.WAGES,
        SHIFT_FIELD_KEYS.REMOVE_SHIFT,
      ],
      netPayShiftFieldKeys: [
        NET_PAY_SHIFT_FIELD_KEYS.WORKED_ON,
        NET_PAY_SHIFT_FIELD_KEYS.WAGES,
        NET_PAY_SHIFT_FIELD_KEYS.NET_TOTAL,
        NET_PAY_SHIFT_FIELD_KEYS.GROSS_TOTAL,
        NET_PAY_SHIFT_FIELD_KEYS.SHIFT_TYPE,
      ],
      shiftFilterKeys: [
        SHIFT_FILTER_KEYS.PAY_SCHEDULE_DATE,
        SHIFT_FILTER_KEYS.START_DATE,
        SHIFT_FILTER_KEYS.END_DATE,
        SHIFT_FILTER_KEYS.SOURCE,
      ],
      absenceFieldKeys: [
        ABSENCE_FIELD_KEYS.STARTED_AT,
        ABSENCE_FIELD_KEYS.ENDED_AT,
        ABSENCE_FIELD_KEYS.DURATION,
        ABSENCE_FIELD_KEYS.IS_PAID,
        ABSENCE_FIELD_KEYS.TYPE,
        ABSENCE_FIELD_KEYS.STATUS,
        ABSENCE_FIELD_KEYS.REFERENCE,
      ],
      streamFieldKeys: [STREAM_FIELD_KEYS.NET_AMOUNT, STREAM_FIELD_KEYS.TYPE, STREAM_FIELD_KEYS.CREATED_AT],
      streamFilterKeys: [STREAM_FILTER_KEYS.STARTS_ON, STREAM_FILTER_KEYS.ENDS_ON],
    };
  },
  computed: {
    employeeMetrics() {
      return [
        {
          value: 0,
          title: 'Earned Wages',
          balanceProp: 'gross_earned',
        },
        {
          value: 0,
          title: 'Streamed Wages (Current)',
          balanceProp: 'transferred',
        },
        {
          value: 0,
          title: 'Streamed Wages (Previous)',
          balanceProp: 'total_debt',
        },
        {
          value: 0,
          title: 'Available To Stream',
          balanceProp: 'available_to_transfer',
        },
      ];
    },
    hideEarnings() {
      return this.state.company.properties.hide_new_earnings && this.employee?.current_state === 'NEW';
    },
    hideSalary() {
      return this.state.company.properties.managers_cannot_view_salary && this.state.claims.m;
    },
    showLumpSumTab() {
      return (
        this.isEnableLumpSumPayPage && this.employee.salary_properties.salary_or_hourly === EMPLOYEE_PAY_TYPE.SALARY
      );
    },
    showLastDayPay() {
      return this.isEnableLastDayPay;
    },
    employeePayScheduleName() {
      if (this.paySchedules === undefined) {
        return '';
      }
      return (
        this.paySchedules.find(paySchedule => paySchedule.pay_schedule_id === this.employee.pay_schedule_id)?.name ||
        'None'
      );
    },
    extraParams() {
      return { employeeId: this.$route.params.employeeId };
    },
    flagContent() {
      let content = '';
      if (this.noRecentShifts) {
        content =
          "Employee hasn't had a shift for 30 days or more. If they have left the company, please disable their account.";
      } else if (this.notYetPaid) {
        if (this.companyCanAutoEnroll) {
          content = "Employee's bank details will be automatically updated by your integration";
        } else {
          content =
            'Employee is yet to be paid through us. Make sure your payroll is updated with their Wagestream bank details.';
        }
      }
      return content;
    },
    flagLevel() {
      if (!this.noRecentShifts) {
        if (this.companyCanAutoEnroll) {
          return ALERT_LEVELS.INFO;
        }
      }
      return ALERT_LEVELS.WARNING;
    },
  },
  setup() {
    const { getEmployeesReport, isNeverPaidEmployee } = useEmployeesReport();
    const {
      isEnableStreamedWagesPage,
      displayNetPayShifts,
      hideShiftsHoursAndRate,
      hideStreamAccountRelatedItems,
      isEnableLumpSumPayPage,
      isEnableLastDayPay,
    } = useFeatureFlags();

    return {
      getEmployeesReport,
      isNeverPaidEmployee,
      isEnableStreamedWagesPage,
      isEnableLumpSumPayPage,
      isEnableLastDayPay,
      displayNetPayShifts,
      hideShiftsHoursAndRate,
      hideStreamAccountRelatedItems,
    };
  },
  async beforeMount() {
    this.employeeId = this.$route.params.employeeId;

    try {
      const [rawEmployee, balance, paySchedules, fieldPermissions] = await Promise.all([
        ApiClient.getPaginatedEmployees({ employee_id: this.employeeId }),
        ApiClient.getEmployeeBalance(this.employeeId),
        ApiClient.getPaySchedules(),
        ApiClient.getCompanyFieldPermissions(this.state.company.company_id),
      ]);

      const employee = rawEmployee.data[0];

      this.employee = this.setEmployeeDefaults(employee);
      this.balance = balance;
      this.paySchedules = paySchedules.data;
      this.fieldPermissions = fieldPermissions;

      for (const key in this.balance) {
        const [filteredEmployeeMetric] = this.employeeMetrics.filter(item => item.balanceProp === key);
        if (filteredEmployeeMetric) {
          filteredEmployeeMetric.value = toCurrency(this.balance[key], State.state.company.default_currency);
        }
      }
    } catch (error) {
      this.errorEmployee = error?.message || 'An error occurred loading the employee.';
    } finally {
      this.loadingEmployee = false;
    }

    this.getFlags();
  },
  methods: {
    async retrieveAndUpdatePageData() {
      this.loadingEmployee = true;
      Promise.all([
        ApiClient.getEmployee(this.employeeId),
        ApiClient.getEmployeeBalance(this.employeeId),
        ApiClient.getPaySchedules(),
        ApiClient.getCompanyFieldPermissions(this.state.company.company_id),
        ApiClient.getDivisions(this.employeeId),
        ApiClient.getEmployeeHistory(this.employeeId),
        ApiClient.getEmployeeEnrollable(this.employeeId),
        ApiClient.getEmployeeSalaryStatements(this.employeeId),
      ]).then(
        ([employee, balance, paySchedules, fieldPermissions, divisions, history, enrollable, salaries]) => {
          this.employee = this.setEmployeeDefaults(employee);
          this.balance = balance;
          this.paySchedules = paySchedules.data;
          this.fieldPermissions = fieldPermissions;
          this.divisions = divisions.data;
          this.history = history;
          this.enrollable = enrollable;
          this.salaries = salaries;
          this.loadingEmployee = false;
        },
        error => {
          this.error = error.message;
          this.loadingEmployee = false;
        },
      );
    },
    async getPay() {
      if (!this.initialPay) {
        return;
      }

      try {
        const [salaries, companyOffCyclePaymentHistory] = await Promise.all([
          ApiClient.getEmployeeSalaryStatements(this.employeeId),
          ApiClient.smeGetExpenseHistory(this.state.company.company_id),
        ]);

        const employeeOffCyclePayments = [];
        for (const paymentRow of companyOffCyclePaymentHistory.data) {
          const { employee_payments } = paymentRow;
          if (Array.isArray(employee_payments)) {
            for (const payment of employee_payments) {
              if (payment.employee_id === this.employeeId) {
                employeeOffCyclePayments.push(payment);
              }
            }
          }
        }

        this.salaries = salaries;
        this.employeeOffCyclePayments = employeeOffCyclePayments;
      } catch (error) {
        this.errorPay =
          error?.message ||
          "An error occurred loading the employee's pay. If this error persists, please contact support.";
      } finally {
        this.loadingPay = false;
        this.initialPay = false;
      }
    },
    async getHistory() {
      if (!this.initialHistory) {
        return;
      }

      try {
        const [history, salaryHistory] = await Promise.all([
          ApiClient.getEmployeeHistory(this.employeeId),
          ApiClient.smeGetSalaryHistory(this.employeeId),
        ]);

        this.history = history;
        this.salaryHistory = salaryHistory.data;
      } catch (error) {
        this.errorHistory =
          error?.message ||
          "An error occurred loading the employee's history. If this error persists, please contact support.";
      } finally {
        this.loadingHistory = false;
        this.initialHistory = false;
      }
    },
    async getLumpSumData() {
      try {
        const payPeriods = await ApiClient.getPayScheduleDates(this.employee.pay_schedule_id);
        const filteredDates = payPeriods.filter(
          payPeriod => moment(payPeriod.pay_period_start).isSameOrAfter(moment(), 'day') || !payPeriod.paid_on,
        );
        this.futurePayPeriods = filteredDates;
      } catch (error) {
        this.errorPaySchedule =
          error?.message ||
          'An error occurred loading the employee pay schedules. If this error persists, please contact support.';
      } finally {
        this.loadingLumpSum = false;
      }
    },
    async getFlags() {
      try {
        // deprecating, this is too slow
        const [, noShiftEmployees] = await Promise.all([
          this.getEmployeesReport(null, null, { neverPaid: true }),
          ApiClient.smeGetEmployeesWithoutShifts(30),
        ]);

        if (
          noShiftEmployees?.data.employees?.some(employee => employee.employee_id === this.employeeId) &&
          this.employee.salary_properties.salary_or_hourly === EMPLOYEE_PAY_TYPE.HOURLY
        ) {
          this.noRecentShifts = true;
        }

        this.notYetPaid = this.isNeverPaidEmployee(this.employeeId);

        if (
          (this.noRecentShifts || (this.notYetPaid && !this.hideStreamAccountRelatedItems)) &&
          this.isEmployeeEnrolled(this.employee)
        ) {
          this.showEmployeeFlag = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async onSave(params) {
      const failureCallback = params.failureCallback;
      const salaryToHourlyDate = params.salaryToHourlyDate;
      const salaryToHourlyChange = params.salaryToHourlyChange;
      const newSalaryProps = {
        salary_or_hourly: EMPLOYEE_PAY_TYPE.HOURLY,
        salary_period: 'YEAR',
        default_wagerate: undefined,
      };
      this.saving = true;
      this.ensureNoEmptyStringsInEmployee();
      try {
        if (salaryToHourlyChange) {
          ApiClient.switchToHourly(this.employee.employee_id, newSalaryProps, salaryToHourlyDate);
        }
        await updateEmployeeAndBanking(this.employee);
        this.saving = false;
        this.$appToast(`${this.employee.full_name}'s details have been updated`, {
          title: 'Successfully updated employee',
          variant: 'success',
        });
      } catch (error) {
        this.employee = failureCallback();
        this.$appToast(
          `Error updating employee: ${
            error ? error.message : 'NULL'
          }. If this error persists, please contact tech support!`,
          {
            title: 'Error updating employee',
            variant: 'warning',
          },
        );
        this.saving = false;
      }
    },
  },
};
</script>
