<template>
  <app-page
    title="Bulk import shifts"
    subtitle="Upload a file of recent time and attendance"
    icon="calendar-circle-plus"
    :previous="[{ label: 'Shifts', to: { name: 'shifts' } }]"
  >
    <bulk-upload name="shift" :schema="FIELD_SCHEMA" :post-function="upload" />
  </app-page>
</template>

<script setup>
import moment from 'moment';
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import ApiClient from '@/ApiClient';
import tracker from '@/Tracker';
import AppPage from '@/components/AppPage.vue';
import BulkUpload from '@/components/BulkUpload.vue';
import IntegrationsState from '@/state/IntegrationsState';
import State from '@/state/State';
import { unFormatCurrency } from '@/utils/common';
import { formatDateIfValid } from '@/utils/date';

const $router = useRouter();

const loading = ref(false);

const FIELD_SCHEMA = [
  {
    key: 'employee_id',
    label: 'employee_id',
    description: 'The employee code',
    example: 'WS-00001',
    type: 'Text',
    required: true,
  },
  {
    key: 'worked_on',
    label: 'worked_on',
    description:
      'The date of the shift in the format <strong>YYYY-MM-DD</strong>. You can change your date format below.',
    example: '2022-02-28',
    type: 'Date',
    required: true,
    formatter: value => formatDateIfValid(value),
    validator: value => moment(value, 'YYYY-MM-DD', true).isValid(),
  },
  {
    key: 'hours',
    label: 'hours',
    description: 'The total hours worked',
    example: '7.5',
    type: 'Number',
    required: true,
    validator: value => !isNaN(value) && value >= 0,
  },
  {
    key: 'wages',
    label: 'wages',
    description: 'The gross pay for this shift in GBP',
    example: '75.36',
    type: 'Currency',
    required: true,
    formatter: unFormatCurrency,
    validator: value => !isNaN(value) && value >= 0,
  },
];

const upload = async (companyId, fileName, fileContent, metadata) => {
  await tracker.trackEvent('sme_live_add_shifts');
  return ApiClient.smeAddBulkShifts(companyId, fileName, fileContent, metadata);
};

onBeforeMount(async () => {
  loading.value = true;
  await ApiClient.getIntegrations(State.state.company.company_id);
  const entities = IntegrationsState.getBrowserUploadEntities();
  if (entities.includes('shifts')) {
    $router.replace({ name: 'sftp-bulk-upload', query: { entity: 'shifts' } });
  }
  loading.value = false;
});
</script>
